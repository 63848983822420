<template>
  <div>
    <!-- TEMPLATE EVENTOS -->
    <div class="cardDetails">
      <!-- HEADER -->
      <a-card class="title">
        <a-row :gutter="24">
          <a-col :span="19">
            {{ offerDetails.title }}
          </a-col>
        </a-row>
      </a-card>

      <a-row class="content-grid" type="flex" :gutter="24">
        <!-- COLUNA DA ESQUERDA -->
        <a-col class="col-session">
          <div class="card-img">
            <div class="video-player">
              <iframe :src="currentVideoUrl" title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen ref="videoPlayer"></iframe>
            </div>
          </div>
          <div class="action">
            <div class="share">
              <div class="action-title">
                <h5>Descrição</h5>
                <p>{{ offerDetails.description }}</p>
              </div>
            </div>
            <div class="favorite" @click="toggleFavorite">
              <svg width="20" height="20" :fill="isFavorite ? 'red' : 'none'" :stroke="isFavorite ? 'red' : '#ffffff'"
                stroke-linecap="round" stroke-linejoin="round" stroke-width="3" viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z">
                </path>
              </svg>
            </div>

          </div>
          <WidgetIndication :visible="drawerVisivel" :association="65465133131"
            :offerLink="'https://www.orendapay.com.br/checklink/16844133337426'" @fecharDrawer="fecharDrawer" />
        </a-col>
      </a-row>

      <!-- DESCRIÇÃO DO CONTEUDO -->
      <a-row style="margin-top: 1rem; margin-bottom: 5rem; cursor: pointer;">
        <a-col v-for="(lesson, index) in lessons" :key="index" @click="selectLesson(index)">
          <a-card style="margin-bottom: 10px;">
            <h5>{{ lesson.title }}</h5>
            <p>{{ offerDetails.description }}</p>
          </a-card>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
  
<script>
import moment from 'moment';

export default {
  data() {
    return {
      lessons: [],
      currentLessonIndex: 0,
      drawerVisivel: false,
      isFavorite: false,
      offerDetails: null,
      offerType: "",
      color: '#f50',
    };
  },
  computed: {
    currentVideoUrl() {
      return this.lessons[this.currentLessonIndex].videoUrl;
    },
  },
  created() {
    const offerId = this.$route.params.offerId;

    this.getOfferDetails(offerId);

  },
  mounted() {

    this.playVideo();

  },
  methods: {
    selectLesson(index) {
      this.currentLessonIndex = index;
      this.playVideo();
    },
    playVideo() {
      const videoPlayer = this.$refs.videoPlayer;
      if (videoPlayer) {
        videoPlayer.load();
        videoPlayer.play();
      }
    },
    formatDate(date) {
      // Verifique se a data está no formato esperado
      const isValidDate = moment(date, 'YYYY-MM-DD', true).isValid();

      if (isValidDate) {
        // Formate a data para o novo formato 'DD-MM-YYYY'
        return moment(date).format('DD/MM/YYYY');
      } else {
        // Se a data não estiver no formato esperado, retorne a data original
        return date;
      }
    },
    toggleFavorite() {
      this.isFavorite = !this.isFavorite;

      const offerId = this.$route.params.offerId;
      const favoriteOffers = JSON.parse(localStorage.getItem('favoriteOffers')) || [];

      if (this.isFavorite) {
        // Adicione o ID da oferta aos favoritos no localStorage
        favoriteOffers.push(offerId);

        var placement = 'bottomLeft';

        this.$notification.open({
          message: `Nova Meta!`,
          description:
            'Esse conteúdo foi adicionado às suas metas',
          placement,
        });

      } else {
        // Remova o ID da oferta dos favoritos no localStorage
        const index = favoriteOffers.indexOf(offerId);
        if (index !== -1) {
          favoriteOffers.splice(index, 1);
        }
      }

      // Atualize o localStorage
      localStorage.setItem('favoriteOffers', JSON.stringify(favoriteOffers));
    },


    translateFormat(type) {
      switch (type) {

        case "PRESENTIAL":
          return "PRESENCIAL"
        case "ONLINE":
          return "ONLINE"
        default:
          return "HÍBRIDO"
      }

    },

    translateType(type) {
      switch (type) {

        case 'Course':
          this.color = '#607e01';
          return 'CURSO'
        case 'Event':
          this.color = '#1ad8ff';
          return 'EVENTO'
        default:
          return 'CONTEÚDO'
      }

    },

    getOfferDetails(offerId) {

      let pathURL = `/course/${offerId}`;

      this.$api.dataService
        .getData(pathURL)
        .then((res) => {
          this.offerDetails = res;
          let newLesson = { title: this.offerDetails.title, videoUrl: this.offerDetails.logo['1x1'] };

          // Adicionando o novo objeto ao array usando push()
          this.lessons.push(newLesson);
          console.log(res);

        })
        .catch((error) => {
          console.error("Erro ao obter detalhes da oferta:", error);
        });
    },
    buyContent() {

      console.log("entrou em curso");

      const id = this.$route.params.offerId;
      const type = this.$route.params.offerType;

      if (type == 'Event') {
        //pega o link do evento e redireciona
        const url = 'https://www.exemplo.com';
        // Abre a URL em uma nova guia
        window.open(url, '_blank');

      } else {

        console.log("teste");

        // this.$api.dataService
        // .save(`${type}/buy/${id}`, null, null)
        // .then((res) => {

        // 	}
        // )
        // .catch((error) => {
        // 	console.error("Erro ao comprar a oferta:", error);
        // })

      }



    },
  }
}
</script>
  
  
<style lang="scss">
.video-player {
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  /* Adicione esta linha para garantir que o iframe não ultrapasse a largura da div */
}

.video-player iframe {
  width: 100% !important;
  /* Adicione esta linha para garantir que o estilo seja aplicado */
  height: 500px;
  /* Mantém a altura em 100% */
}

.cardDetails {
  width: 100%;
}

.title {
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;

  margin-bottom: 20px;
}

@media (max-width: 600px) {
  .title {
    font-size: 16px;
  }
}

/* GRID ROW CARDS */
.content-grid {
  grid-template-columns: repeat(2, 1fr);
}

/* COLUM LEFT */
.col-session:nth-child(1) {
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;

  .card-img {
    height: 100%;
    display: flex;
    align-items: end;

    border-radius: 10px;
    overflow: hidden;

    img {
      height: 27.8rem;
      width: 100%;
      object-fit: cover;
    }
  }

  @media (max-width: 700px) {
    .card-img {
      img {
        height: 20rem;
      }
    }
  }

  @media (max-width: 450px) {
    .card-img {
      img {
        height: 15rem;
      }
    }
  }

  .action {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    gap: 10px;

    h5 {
      font-size: 14px;
    }

    .share {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 12px;
      padding: 1rem;
      border-radius: 12px;

      .action-title {
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .favorite {
      height: 60px;
      width: 60px;

      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem;
      border-radius: 12px;
    }
  }
}

/* COLUM RIGHT */
.col-session {
  width: 40%;

  display: flex;
  flex-direction: column;
  gap: 24px;

  /* INFORMAÇÕES DE CARD DOS PRODUTOS RIGHT*/
  .infos {
    border: 1px solid #232b3a;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    padding: 1rem;
    display: flex;
    flex-direction: row;
    border-radius: 12px;
    gap: 12px;

    .icon {
      height: 45px;
      width: 45px;
      border-radius: 8px;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text {
      .headerTitle {
        font-size: 16px;
        margin: 0;
        padding: 0;
        text-align: left;
      }

      p {
        font-size: 14px;
        margin: 0;
        padding: 0;
        text-align: left;
      }
    }
  }

  .info {
    p {
      margin: 0;
      font-size: 14px;
    }

    h5 {
      margin: 0;
      font-size: 16px;
    }
  }

  /* INFORMAÇÕES IMPORTANTES */
  .price {
    border: 1px solid #232b3a;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    padding: 1rem;
    border-radius: 12px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;

    .headerTitle {
      margin: 0;
      padding: 0;
      margin-top: 10px;
    }

    .value {
      color: #fff;
      font-size: 40px;
      font-weight: 700;

      span {
        font-size: 20px;
      }
    }
  }

  .buttonStart {
    display: flex;
    width: 100%;
    padding: 24px 0px;
    flex-direction: column;
    align-items: center;

    border-radius: 8px;
    background: #3dc790;
    color: #fff;
    font-weight: 700;
    font-size: 16px;

    box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02),
      0px 4px 9px 1px rgba(50, 71, 92, 0.04),
      0px 2px 9px 0px rgba(50, 71, 92, 0.06);
  }
}

/* TEMPLATE CURSOS */
.grid-infos {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;

  .info-course {
    padding: 1rem;
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;
    border-radius: 12px;
    box-shadow: 0px 20px 27px rgba(0, 0, 0, 0.05);
    border: 1px solid #232b3a;

    p {
      margin: 0;
      font-size: 12px;
      white-space: nowrap;
    }

    h5 {
      margin: 0;
      font-size: 16px;
    }
  }
}

@media (max-width: 1157px) {
  .grid-infos {
    grid-template-columns: 1fr;
    gap: 10px;

    .info-course {
      padding: 0.7rem;

      p {
        font-size: 10px;
      }

      h5 {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 700px) {
  .grid-infos {
    grid-template-columns: 1fr 1fr;

    .info-course {
      padding: 1rem;

      p {
        font-size: 12px;
      }

      h5 {
        font-size: 16px;
      }
    }
  }
}

@media (max-width: 700px) {
  .content-grid {
    grid-template-columns: repeat(1, 1fr);

    .col-session:nth-child(1) {
      width: 100%;
    }
  }

  .col-session {
    width: 100%;
  }

  .col-session:nth-child(2) {
    margin-top: 1rem;
  }
}
</style>
  
  